export const tipoOperacao = (contratoStatusId, contratoTipoOperacao) => {

    const tipoOperacao = [
        { id: 1, descricao: 'Inclusão do registro de contrato' },
        { id: 2, descricao: 'Alteração do registro de contrato' },
        { id: 3, descricao: 'Inclusão do aditivo de contrato' },
        { id: 4, descricao: 'Alteração do aditivo de contrato' },
    ]

    if (contratoStatusId !== 1 && contratoTipoOperacao === 1) return tipoOperacao // todos
    if (contratoStatusId !== 1 && contratoTipoOperacao === 2) return tipoOperacao.filter((item) => item.id !== 1) // 2,3,4
    if (contratoStatusId !== 1 && contratoTipoOperacao === 3) return tipoOperacao.filter((item) => item.id !== 2 && item.id !== 1) // 3 e 4
    if (contratoStatusId !== 1 && contratoTipoOperacao === 4) return tipoOperacao.filter((item) => item.id === 4) // 4

    //regras de status 1 que siginifica contrato cadastrado com sucesso
    if (contratoStatusId === 1 && (contratoTipoOperacao === 1 || contratoTipoOperacao === 2)) return tipoOperacao.filter((item) => item.id !== 1) // 2,3,4
    if (contratoStatusId === 1 && contratoTipoOperacao === 3) return tipoOperacao.filter((item) => item.id !== 1 && item.id !== 2) // 3 e 4
    if (contratoStatusId === 1 && contratoTipoOperacao === 4) return tipoOperacao.filter((item) => item.id === 4) // 4


}

export const tipoOperacaoRJ = (contratoStatusId, contratoTipoOperacao, addContract, updateContract, addtive, updateAdtive) => {
    const tipoOperacao = [
        { id: 1, descricao: 'Inclusão do registro de contrato', can: addContract },
        { id: 2, descricao: 'Alteração do registro de contrato', can: updateContract },
        { id: 3, descricao: 'Inclusão do aditivo de contrato', can: addtive },
        { id: 4, descricao: 'Alteração do aditivo de contrato', can: updateAdtive },
    ];

    let filteredOperacao;

    if (contratoStatusId !== 1 && contratoTipoOperacao === 1) {
        filteredOperacao = tipoOperacao; // todos
    } else if (contratoStatusId !== 1 && contratoTipoOperacao === 2) {
        filteredOperacao = tipoOperacao.filter((item) => item.id !== 1); // 2, 3, 4
    } else if (contratoStatusId !== 1 && contratoTipoOperacao === 3) {
        filteredOperacao = tipoOperacao.filter((item) => item.id !== 1 && item.id !== 2); // 3 e 4
    } else if (contratoStatusId !== 1 && contratoTipoOperacao === 4) {
        filteredOperacao = tipoOperacao.filter((item) => item.id === 4); // 4
    } else if (contratoStatusId === 1 && (contratoTipoOperacao === 1 || contratoTipoOperacao === 2)) {
        filteredOperacao = tipoOperacao.filter((item) => item.id !== 1); // 2, 3, 4
    } else if (contratoStatusId === 1 && contratoTipoOperacao === 3) {
        filteredOperacao = tipoOperacao.filter((item) => item.id !== 1 && item.id !== 2); // 3 e 4
    } else if (contratoStatusId === 1 && contratoTipoOperacao === 4) {
        filteredOperacao = tipoOperacao.filter((item) => item.id === 4); // 4
    } else {
        filteredOperacao = [];
    }

    // Remove itens com `can: false`
    return filteredOperacao.filter((item) => item.can);
};


